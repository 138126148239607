.Modal {
  position: absolute;
  inset: 80px;
  border: 1px solid var(--blueish-white);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 3rem;
  outline: none;
  padding: 20px;
  background-color: var(--background-color);
  max-width: 60%;
  margin: 1.2rem auto;
  caret-color: transparent;
}

.ModalOverlay {
  position: fixed;
  inset: 0px;
  /* change the opacity of th background */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  overflow-x: hidden;
  overflow-y: auto;
}
.ModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 1rem; */
}
.ModalHeaderTitle {
  font-size: 1.5rem;
}
.ModalSeparatorLine {
  flex: auto;
  height: 0.1rem;
  background-color: var(--blueish-white);
  width: 100%;
}
.ModalMarkdown {
  margin-left: 5%;
  margin-right: 5%;
}
.ModalMarkdown h1 {
  font-size: 1.5rem;
}
.ModalMarkdown video {
  max-width: 80%;
  max-height: 100%;
  margin: auto;
  display: block;
}
.ModalMarkdown img {
  max-height: 15rem;
  margin: auto;
  display: block;
}
.ModalMarkdown a {
  color: var(--secondary-color);

  font-weight: 500;
}
.ModalMarkdown p {
  line-height: 1.75;
}
.ModalMarkdown strong {
  font-weight: 600;
  color: orange;
}

.ModalMarkdown li {
  display: inline-block;
  padding-right: 1em;
}

.ModalHeaderTitleContainer {
  display: flex;
  align-items: center;
  gap: 1.8rem;
}
.github-icon {
  justify-content: center;
  align-items: center;
  display: flex;
}
.github-icon > img {
  height: 2rem;
  width: 2rem;
}

.close {
  overflow: hidden;
  position: relative;
  border: none;
  padding: 0;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background: transparent;
  color: #ffffff;
  font: inherit;
  text-indent: 100%;
  cursor: pointer;
}

.close:focus {
  outline: solid 0 transparent;
  box-shadow: 0 0 0 2px #ffffff;
}

.close:hover {
  background: rgba(29, 161, 142, 0.1);
}

.close:before,
.close:after {
  position: absolute;
  top: 15%;
  left: calc(50% - 0.0625em);
  width: 0.125em;
  height: 70%;
  border-radius: 0.125em;
  transform: rotate(45deg);
  background: currentcolor;
  content: "";
}

.close:after {
  transform: rotate(-45deg);
}
