.perlin-noise-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(8px);
  outline: 10px solid black;
}
@media screen and (max-width: 750px) {
  .perlin-noise-background {
    display: none;
  }
  body {
    background-color: var(--background-color);
  }
}
