:root {
  --primary-color: #3ac3b8;
  --secondary-color: #e6f1fc;
  --background-color: #283849;
  --dark-background-color: #1f2c39;
  --text-color: #ffffff;
  --blueish-white: #83fbd1;
}
html {
  scroll-behavior: smooth;
}
.App {
  margin-left: calc(100% / 6);
  margin-right: calc(100% / 6);
  color: var(--text-color);
  caret-color: transparent;
  font-size: 16px; /* Taille de base pour les ordinateurs */
}

@media (max-width: 750px) {
  .App {
    margin-left: 5%;
    margin-right: 5%;
    font-size: 14px; /* Taille de base pour les téléphones */
  }
}
