.button-main-container {
  display: flex;
  padding: 0px 2rem;
  border-radius: 60px;
  border: 4px solid #eaecee;
  background: #33aba0;
  text-decoration: none;
  margin: 10px;
  justify-content: center;
  align-items: center;
}

.button-text {
  color: #fff;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}

@media (max-width: 750px) {
  .button-main-container {
    display: flex;
    padding: 0px 1rem;
    border-radius: 30px;
    border: 2px solid #eaecee;
    background: #33aba0;
    text-decoration: none;
    margin: 10px;
    align-content: center;
  }
  .button-text {
    align-items: center;
    color: #fff;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
  }
}
