.MainWorkExperienceContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}
.ExperienceContainer {
  display: flex;
  flex-direction: column;
  width: 70%;
  min-height: 20vh;
  border-radius: 50px;
  box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
  background-color: var(--background-color);
}
.Top {
  display: table;
  padding: 1rem 1rem 0;
  margin-bottom: 0.5em;
}
.Top > div,
.Top > h3 {
  display: table-cell;
}
.Top > h3 {
  text-align: left;
  vertical-align: middle;
  font-size: 1.2rem;
}
.Location-Date {
  text-align: right;
  vertical-align: bottom;
  font-size: 0.8rem;
  opacity: 0.8;
}
.row {
  border-top: 1px solid var(--blueish-white);
  padding-top: 1.5em;
}
.Logo {
  vertical-align: middle;
  object-fit: contain;
  height: 3rem;
  margin: 0 1rem;
}
.Description {
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
}
.BulletPoints {
  font-size: 1rem;
  text-justify: inter-word;
}

@media screen and (max-width: 750px) {
  .MainWorkExperienceContainer {
    gap: 2rem;
  }
  .ExperienceContainer {
    width: 90%;
  }
  .Top {
    padding: 0.5rem;
  }
  .Top > h3 {
    font-size: 0.7rem;
  }
  .Location-Date {
    font-size: 0.5rem;
  }
  .Logo {
    height: 2rem;
    margin: 0 0.5rem;
  }
  .Description {
    font-size: 0.8rem;
  }
  .BulletPoints {
    font-size: 0.7rem;
  }
}
