.AboutText {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
  text-shadow: 1px 1px 2px 0.9 black;
}
@media (max-width: 750px) {
  .AboutText {
    font-size: 1rem;
  }
}
