.footer {
  background-color: var(--dark-background-color);
  margin-top: 3em;
  padding: 1vh;
  align-items: center;
}
.footer ul {
  list-style: none;
  padding: 1em 0em;
  margin: 0;
  display: flex;
  justify-content: center;
}
.footer li {
  font-size: 1rem;
  padding: 0 1em;
  font-weight: 500;
}

.footer a {
  color: var(--secondary-color);
  text-decoration: none;
}

.NavElement {
  flex: auto;
  display: inline;
  color: var(--text-color);
  text-decoration: none;
  text-align: center;
}

.footer a:hover {
  color: var(--primary-color);
  transition: 100ms ease-in-out;
}
