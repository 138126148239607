.LinkButton {
  border-radius: 100px;
  text-decoration: none;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  gap: 3rem;
  padding-left: 1.2rem;
  font-style: normal;
  font-weight: 600;
}

.LinkButton:hover {
  transform: scale(1.1);
  transition: 100ms ease-in-out;
  box-shadow: 0 0 0.5rem 0.2rem var(--primary-color);
}

.LinkButton:nth-child(odd) {
  background: var(--secondary-color);
  color: var(--primary-color);
}
.LinkButton:nth-child(even) {
  background: var(--primary-color);
  color: var(--secondary-color);
}

.LinkButton__icon {
  height: 3rem;
  width: 3rem;
}

.LinkButton__icon > svg {
  height: 3rem;
  width: 100%;
  color: inherit;
}
.LinkButton__icon:nth-child(even) {
  color: var(--primay-color);
}
.LinkButton__icon:nth-child(odd) {
  color: var(--secondary-color);
}
.LinkButton__icon > svg > path {
  height: 2rem;
  width: 100%;
  color: inherit;
}

.LinkButton__text {
  flex: auto;
  align-self: center;
  white-space: nowrap;
}

@media (max-width: 750px) {
  .LinkButton {
    font-size: 1rem;
    gap: 1.5rem;
    padding-left: 0.6rem;
  }
  .LinkButton__icon {
    height: 2rem;
    width: 2rem;
  }
  .LinkButton__icon > svg {
    height: 2rem;
    width: 100%;
  }
  .LinkButton__icon > svg > path {
    height: 1.5rem;
    width: 100%;
  }
}
