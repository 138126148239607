.Separator {
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
  margin-top: 3rem;
}
.SeparatorText {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 40px; /* 62.5% */
}
.SeparatorLine {
  flex: auto;
  height: 0.3rem;
  border-radius: 10rem;
  background-color: var(--primary-color);
  align-self: center;
  margin-left: 3rem;
  opacity: 0.3;
}
@media (max-width: 800px) {
  .SeparatorText {
    font-size: 1.2rem;
  }
}
